const defaultScrollLimit = 15

export default {
    data() {
        return {
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
        }
    },
    computed: {
        listData() {
            console.error("In your component, overwrite 'listData'.")
        },
        listDataPortion() {
            if (this.listData) {
                return this.listData.slice(0, this.scrollLimit)
            }
            return []
        },
    },
    methods: {
        resetListLength() {
            this.scrollLimit = defaultScrollLimit
        },
        /**
         * Loads a chunk more data for the list.
         * Meant to be used like this: `<List v-infinite-scroll="loadMoreListData">`
         */
        loadMoreListData() {
            if (this.scrollLimit < this.listData?.length) {
                this.scrollLimit += this.scrollStep
            }
        },
        /**
         * This will load data in the infiniti scroll list until a element with a classname is found.
         * Then the callback function is called.
         * @param className String Class name
         * @param thenFunction Callable Callback function. Will be called with one param of type Boolean. True if a element was found, false otherwise.
         */
        loadListDataUntilFound(className, thenFunction) {
            // Using setTimeout with timeout "0" to schedule a new task so UI is not freezing while loading data
            setTimeout(() => {
                if (this.scrollLimit >= this.listData?.length) {
                    // making sure we don't get an infinite loop
                    thenFunction(false)
                    return
                }

                const [ele] = this.$el.getElementsByClassName(className)

                if (!ele) {
                    this.loadMoreListData()
                    this.loadListDataUntilFound(className, thenFunction)
                } else {
                    thenFunction(true)
                }
            }, 0)
        },
    },
}
