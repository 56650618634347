<template>
    <div class="l-stack">
        <div
            class="l-inline l-spread hidden-form_title l-padded"
            :class="titleClass"
            @click="toggleForm()"
        >
            <slot name="title" />
            <IconButton>
                <ArrowDownIcon
                    width="12"
                    height="12"
                    class="arrow-icon"
                    :class="{ 'arrow-icon-rotated': isHidden }"
                />
            </IconButton>
        </div>
        <transition name="dropdown">
            <div v-show="!isHidden" class="dropdown">
                <slot name="form" />
            </div>
        </transition>
    </div>
</template>

<script>
import ArrowDownIcon from './icons/ArrowDownIcon'
import IconButton from './IconButton'

export default {
    name: 'HiddenForm',
    components: {
        ArrowDownIcon,
        IconButton,
    },
    props: {
        hidden: {
            type: Boolean,
            default: true,
        },
        titleClass: {
            type: [Array, Object, String],
            default: null,
        },
    },
    data() {
        return {
            isHidden: this.hidden,
        }
    },
    watch: {
        hidden(newHidden) {
            this.isHidden = newHidden
        },
    },
    methods: {
        toggleForm() {
            this.isHidden = !this.isHidden
            this.$emit('toggle', this.isHidden)
        },
    },
}
</script>

<style lang="scss" scoped>
.hidden-form_title {
    cursor: pointer;

    &:hover {
        background-color: $color-gray-light;
    }
}

.dropdown {
    &-enter,
    &-leave-to {
        max-height: 0;
        opacity: 0;
    }

    &-leave,
    &-enter-to {
        max-height: 1000px;
        opacity: 1;
    }

    &-enter-active,
    &-leave-active {
        transition: all 0.2s linear;
    }
}

.arrow-icon {
    transform: rotate(0.5turn);
    transition: transform 0.2s;

    &.arrow-icon-rotated {
        transform: rotate(0);
    }
}
</style>
