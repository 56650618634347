<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="9.75" y1="14.248" x2="0.75" y2="23.248" />

        <polyline class="a" points="23.25 7.498 23.25 0.748 16.5 0.748" />

        <polyline class="a" points="0.75 16.498 0.75 23.248 7.5 23.248" />

        <line class="a" x1="23.25" y1="0.748" x2="14.25" y2="9.748" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
