<template>
    <div class="l-padded chart-container">
        <div v-if="isLoading || isLoadingData">
            <VSpinner size="medium" line-fg-color="#000" :speed="1" />
        </div>

        <div v-else-if="series[0].data.length" class="chart-container__content">
            <ApexCharts
                height="100%"
                :options="chartOptions"
                :series="series"
                @scrolled="handleZoom"
                @zoomed="handleZoom"
            />

            <div>
                <VSlider
                    v-model="sliderValue"
                    adsorb
                    drag-on-click
                    :data="sliderValues"
                    :duration="0"
                    :tooltip-formatter="sliderTooltipFormatter"
                    :tooltip="sliderValue ? 'active' : 'none'"
                    @change="handleSliderValueChange"
                />
            </div>

            <p
                v-if="sliderValues.length > 1"
                class="l-inline l-gap-2 l-center-v l-spread"
            >
                <span>{{ timeStartFormatted }}</span>

                <span class="l-inline l-gap-2">
                    <IconButton
                        no-button
                        :hover-color="iconButtonHoverColor"
                        @click="handlePlayingAccelerationChange"
                    >
                        {{ playingAcceleration }}X
                    </IconButton>

                    <IconButton
                        :hover-color="iconButtonHoverColor"
                        @click="isPlaying = !isPlaying"
                    >
                        <ControlsPauseIcon v-if="isPlaying" />

                        <ControlsPlayIcon v-else />
                    </IconButton>

                    <IconButton
                        :hover-color="iconButtonHoverColor"
                        @click="isPlayingLoop = !isPlayingLoop"
                    >
                        <LoopIcon :color="isPlayingLoop ? '#000' : '#777'" />
                    </IconButton>
                </span>

                <span>
                    {{ timeEndFormatted }}
                </span>
            </p>
        </div>

        <p v-else>
            {{ $t('noResults') }}
        </p>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { debounce, throttle } from 'lodash'
import moment from 'moment-timezone'
import ApexCharts from 'vue-apexcharts'
import VSlider from 'vue-slider-component'
import VSpinner from 'vue-simple-spinner'

import { httpHelper, measurementHelper } from '@/utils'
import ChartHelper from '@/mixins/ChartHelper'
import ControlsPauseIcon from './icons/ControlsPauseIcon'
import ControlsPlayIcon from './icons/ControlsPlayIcon'
import IconButton from './IconButton'
import LoopIcon from './icons/LoopIcon'

export default {
    name: 'AssetLocationHistoryMeasurementsChart',
    components: {
        ApexCharts,
        ControlsPauseIcon,
        ControlsPlayIcon,
        IconButton,
        LoopIcon,
        VSlider,
        VSpinner,
    },
    mixins: [ChartHelper],
    props: {
        historyPoints: {
            type: Array,
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        id: {
            type: [String, Number],
            required: true,
        },
        measurement: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    type: 'line',
                    stacked: false,
                    animations: {
                        enabled: false,
                    },
                    ...(this.measurement === 'accel_y' && {
                        type: 'scatter',
                    }),
                },
                grid: {
                    show: false,
                },
                stroke: {
                    curve: 'straight',
                    width: 3,
                },
                colors: [process.env.VUE_APP_COLOR_PRIMARY],
                dataLabels: {
                    enabled: false,
                },
                markers: {
                    size: 0,
                    style: 'full',
                    ...(this.measurement === 'accel_y' && {
                        size: 8,
                    }),
                },
                yaxis: {
                    min: this.getYMinValue(this.measurement),
                    max: this.getYMaxValue(this.measurement),
                    floating: true,
                    tickAmount: 2,
                    labels: {
                        offsetY: -4,
                        offsetX: 44,
                        maxWidth: 100,
                        align: 'left',
                    },
                    ...(['bin', 'sbb-bin'].includes(this.assetType) &&
                        this.measurement === 'distance' && {
                            min: 0,
                            max: 100,
                        }),
                    ...(this.measurement === 'accel_y' && {
                        show: false,
                        tickAmount: 2,
                        min: 0,
                        max: 2,
                    }),
                },
                xaxis: {
                    type: 'datetime',
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                    ...(this.measurement === 'accel_y' && {
                        custom: () => null,
                    }),
                },
            },
            iconButtonHoverColor: 'rgba(0, 0, 0, 0.05)',
            isLoadingData: false,
            isPlaying: false,
            isPlayingLoop: false,
            playingAcceleration: 1,
            playingAccelerationOptions: [0.5, 1, 1.5, 2],
            playingIndex: null,
            playingIntervalId: null,
            series: [{ data: [] }],
            sliderValue: null,
            timeBounds: null,
        }
    },
    computed: {
        ...mapState('map', ['mapInstance']),
        ...mapState('tracker', ['assetHistory', 'assetHistoryLocationId']),
        converter() {
            if (this.measurement === 'distance') {
                if (this.assetType === 'bin') {
                    return measurementHelper.convertToBinLevel
                } else if (this.assetType === 'sbb-bin') {
                    return measurementHelper.convertToSbbBinLevel
                }
            }

            return (
                measurementHelper.converters[this.measurement] ||
                measurementHelper.converters.default
            )
        },
        dateRange() {
            const startDate = this.historyPoints[0]?.timestamp
            const endDate = this.historyPoints[this.historyPoints.length - 1]
                ?.timestamp
            return { startDate, endDate }
        },
        historyPointsFiltered() {
            return this.timeBounds
                ? this.historyPoints.filter(item => {
                      const time = new Date(item.timestamp)
                      return (
                          time >= this.timeBounds[0] &&
                          time <= this.timeBounds[1]
                      )
                  })
                : this.historyPoints
        },
        measurementTranslated() {
            return this.$root.$te(`shared.measurements.${this.measurement}`)
                ? this.$t(`shared.measurements.${this.measurement}`)
                : this.measurement
        },
        sliderValues() {
            return this.historyPointsFiltered.map(item => item.timestamp)
        },
        timeEndFormatted() {
            return this.sliderTooltipFormatter(
                this.timeBounds?.[1] ||
                    this.sliderValues[this.sliderValues.length - 1]
            )
        },
        timeStartFormatted() {
            return this.sliderTooltipFormatter(
                this.timeBounds?.[0] || this.sliderValues[0]
            )
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                const { playbackspeed } = this.$route.query
                if (playbackspeed) {
                    const parsed = JSON.parse(playbackspeed)
                    if (parsed?.every(Number.isFinite)) {
                        this.playingAccelerationOptions = parsed
                    }
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            playbackspeed: undefined,
                        },
                    })
                }
            },
        },
        assetHistoryPointProposed() {
            if (this.assetHistoryPointProposed) {
                this.sliderValue = this.historyPoints.find(
                    ({ position: [lat, lng] }) =>
                        lat === this.assetHistoryPointProposed[0] &&
                        lng === this.assetHistoryPointProposed[1]
                )?.timestamp
                this.setAssetHistoryPointProposed(null)
            }
        },
        historyPoints() {
            this.loadHistory()
        },
        id() {
            this.loadHistory()
        },
        isPlaying() {
            if (!this.isPlaying) {
                clearInterval(this.playingIntervalId)
                return
            }

            this.playingIndex = this.sliderValues.indexOf(this.sliderValue)
            this.playingIntervalId = setInterval(() => {
                if (this.playingIndex === this.sliderValues.length - 1) {
                    if (this.isPlayingLoop) {
                        this.playingIndex = -1
                    } else {
                        this.isPlaying = false
                        this.sliderValue = null
                        this.$emit('pointChange', null)
                        return
                    }
                }
                this.sliderValue = this.sliderValues[++this.playingIndex]
                this.$emit('pointChange', this.historyPoints[this.playingIndex])
            }, 12 / this.playingAcceleration)
        },
        measurement() {
            this.updateYAxis()
            this.loadHistory()
        },
        playingAcceleration() {
            if (this.isPlaying) {
                this.isPlaying = false
                this.$nextTick(() => {
                    this.isPlaying = true
                })
            }
        },
        assetHistoryLocationId() {
            if (this.assetHistoryLocationId) {
                this.sliderValue = this.assetHistory.find(
                    item => item.id === this.assetHistoryLocationId
                )?.timestamp
            }
        },
    },
    mounted() {
        this.loadHistory()
    },
    beforeDestroy() {
        if (this.playingIntervalId) {
            clearInterval(this.playingIntervalId)
        }
    },
    methods: {
        ...mapMutations('tracker', ['setAssetHistoryPointProposed']),
        updateYAxis() {
            this.chartOptions.yaxis.min = this.getYMinValue(this.measurement)
            this.chartOptions.yaxis.max = this.getYMaxValue(this.measurement)
        },
        async loadHistory() {
            this.isLoadingData = true
            const results = await this.loadData()
            this.series = [
                {
                    name: this.measurementTranslated,
                    data: results
                        .filter(item =>
                            Object.prototype.hasOwnProperty.call(
                                item.sensor_data,
                                this.measurement
                            )
                        )
                        .map(item => [
                            item.timestamp,
                            this.converter(item.sensor_data[this.measurement]),
                        ]),
                },
            ]
            this.isLoadingData = false
        },
        async loadData() {
            if (!this.dateRange.startDate && !this.dateRange.endDate) {
                return []
            }

            let results = []
            let historyUrl =
                'measurements/?' +
                `timestamp_min=${encodeURIComponent(
                    moment(this.dateRange.startDate).format()
                )}` +
                `&timestamp_max=${encodeURIComponent(
                    moment(this.dateRange.endDate).format()
                )}` +
                `&tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (historyUrl) {
                const { data } = await httpHelper.get(historyUrl)
                results = results.concat(data.results)
                historyUrl = data.next
            }

            return results
        },
        handlePlayingAccelerationChange() {
            let index = this.playingAccelerationOptions.indexOf(
                this.playingAcceleration
            )
            this.playingAcceleration = this.playingAccelerationOptions[
                ++index % this.playingAccelerationOptions.length
            ]
        },
        handleSliderValueChange: throttle(function(timestamp) {
            this.$emit(
                'pointChange',
                this.historyPoints.find(item => item.timestamp === timestamp)
            )

            if (this.isPlaying) {
                this.playingIndex = this.sliderValues.indexOf(this.sliderValue)
            }
        }, 50),
        handleZoom: debounce(function(_, { xaxis: { min, max } }) {
            this.timeBounds = min && max ? [new Date(min), new Date(max)] : null
            this.mapInstance.flyToBounds(
                this.historyPointsFiltered.map(item => item.position)
            )
        }, 100),
        sliderTooltipFormatter(timestamp) {
            return timestamp
                ? moment(timestamp).format('DD.MM.YYYY HH:mm:ss')
                : null
        },
    },
}
</script>

<i18n>
{
    "en": {
        "noResults": "No results"
    },
    "de": {
        "noResults": "Keine Ergebnisse"
    },
    "fr": {
        "noResults": "Aucun résultat"
    },
    "it": {
        "noResults": "Nessun Risultato"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-container {
    text-align: center;

    &__content {
        & > * {
            &:not(:first-child) {
                padding-right: 10px;
                padding-left: 12px;
                font-size: 13px;
            }
        }

        .icon-button {
            min-width: 18px;
            height: 18px;
            line-height: 18px;
        }
    }
}
</style>
