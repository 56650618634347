<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <polyline class="a" points="15 8.754 15 14.254 9 14.254 9 8.754" />
        <polyline class="a" points="7.5 9.754 12 6.754 16.5 9.754" />

        <path
            class="a"
            d="M21,9.754c0,7.9-6.932,12.331-8.629,13.3a.749.749,0,0,1-.743,0C9.931,22.084,3,17.652,3,9.754a9,9,0,0,1,18,0Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'PinHomeIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: 'Indoor Measurement',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
