<template>
    <SignalLowIcon
        v-if="signalStrength === 'low'"
        :name="$t('signalLow')"
        width="24"
        height="24"
        color="#d32f2f"
    />
    <SignalMediumIcon
        v-else-if="signalStrength === 'medium'"
        :name="$t('signalMedium')"
        width="24"
        height="24"
        color="#fdae61"
    />
    <SignalHighIcon
        v-else-if="signalStrength === 'high'"
        :name="$t('signalHigh')"
        width="24"
        height="24"
        color="#41b883"
    />
</template>

<script>
import SignalHighIcon from './icons/SignalHighIcon'
import SignalLowIcon from './icons/SignalLowIcon'
import SignalMediumIcon from './icons/SignalMediumIcon'

export default {
    components: {
        SignalHighIcon,
        SignalLowIcon,
        SignalMediumIcon,
    },
    props: {
        networkGsmSignal: {
            type: Number,
            default: null,
        },
        networkRssi: {
            type: Number,
            default: null,
        },
        networkSnr: {
            type: Number,
            default: null,
        },
    },
    computed: {
        networkEsp() {
            return typeof this.networkRssi === 'number' &&
                typeof this.networkSnr === 'number'
                ? this.networkRssi -
                      10 * Math.log(1 + Math.pow(10, -this.networkSnr / 10))
                : null
        },
        signalStrength() {
            switch (this.networkGsmSignal) {
                case 5:
                    return 'hight'
                case 4:
                case 3:
                    return 'medium'
                case 2:
                case 1:
                case 0:
                    return 'low'
            }

            return typeof this.networkEsp === 'number'
                ? this.networkEsp < -110
                    ? 'low'
                    : this.networkEsp < -100
                    ? 'medium'
                    : 'high'
                : null
        },
    },
}
</script>

<i18n>
{
    "en": {
        "signalLow": "low signal strength",
        "signalMedium": "medium signal strength",
        "signalHigh": "high signal strength"
    },
    "de": {
        "signalLow": "niedrige Signalstärke",
        "signalMedium": "mittlere Signalstärke",
        "signalHigh": "hohe Signalstärke"
    },
    "fr": {
        "signalLow": "Force du signal faible",
        "signalMedium": "Force du signal moyen",
        "signalHigh": "Force du signal haute"
    },
    "it": {
        "signalLow": "bassa potenza del segnale",
        "signalMedium": "intensità media del segnale",
        "signalHigh": "elevata potenza del segnale"
    }
}
</i18n>
