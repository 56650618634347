<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="'none'"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M11.25,21C10.009,21,9,19.991,9,18.75v-9c0-1.241,1.009-2.25,2.25-2.25h1.5C13.991,7.5,15,8.509,15,9.75v9
		c0,1.241-1.009,2.25-2.25,2.25H11.25z M11.25,9c-0.414,0-0.75,0.336-0.75,0.75v9c0,0.414,0.336,0.75,0.75,0.75h1.5
		c0.414,0,0.75-0.336,0.75-0.75v-9C13.5,9.336,13.164,9,12.75,9H11.25z"
        />
        <path
            d="M2.25,21C1.009,21,0,19.991,0,18.75v-3c0-1.241,1.009-2.25,2.25-2.25h1.5C4.991,13.5,6,14.509,6,15.75v3
		C6,19.991,4.991,21,3.75,21H2.25z M2.25,15c-0.414,0-0.75,0.336-0.75,0.75v3c0,0.414,0.336,0.75,0.75,0.75h1.5
		c0.414,0,0.75-0.336,0.75-0.75v-3C4.5,15.336,4.164,15,3.75,15H2.25z"
        />
        <path
            d="M18.75,21C18.336,21,18,20.664,18,20.25s0.336-0.75,0.75-0.75h4.5c0.414,0,0.75,0.336,0.75,0.75S23.664,21,23.25,21H18.75z
		"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'SearchIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
.b {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
