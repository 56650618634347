<template>
    <Icon :name="name" :width="width" :height="height" @click="$emit('click')">
        <rect
            class="a"
            x="5.25"
            y="3.75"
            width="4.5"
            height="16.5"
            rx="1.5"
            ry="1.5"
        />

        <rect
            class="a"
            x="14.25"
            y="3.75"
            width="4.5"
            height="16.5"
            rx="1.5"
            ry="1.5"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ControlsPauseIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
