<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle :color="color" class="a" cx="12" cy="9.75" r="6" />

        <path
            class="a"
            :color="color"
            d="M6.8,6.751H8.25a1.5,1.5,0,0,1,1.5,1.5v.7a1.5,1.5,0,0,0,.668,1.248l.958.639a1.4,1.4,0,0,1,0,2.332L8.845,14.854"
        />

        <path
            class="a"
            :color="color"
            d="M17.2,6.751h-.7a1.5,1.5,0,0,0-1.342,2.171c.427.854.525.895.79,1.054l1.9,1.138"
        />

        <path
            class="a"
            :color="color"
            d="M21,9.75c0,7.9-6.932,12.331-8.629,13.3a.751.751,0,0,1-.743,0C9.931,22.08,3,17.648,3,9.75a9,9,0,0,1,18,0Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'PinEarthIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
