<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="''"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M17.25,24c-3.722,0-6.75-3.028-6.75-6.75s3.028-6.75,6.75-6.75S24,13.528,24,17.25S20.972,24,17.25,24z M17.25,12
		C14.355,12,12,14.355,12,17.25s2.355,5.25,5.25,5.25s5.25-2.355,5.25-5.25S20.145,12,17.25,12z"
        />
        <path
            d="M17.25,21c-0.414,0-0.75-0.336-0.75-0.75V18h-2.25c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h2.25v-2.25
		c0-0.414,0.336-0.75,0.75-0.75S18,13.836,18,14.25v2.25h2.25c0.414,0,0.75,0.336,0.75,0.75S20.664,18,20.25,18H18v2.25
		C18,20.664,17.664,21,17.25,21z"
        />
        <path
            d="M3.75,16.5C3.336,16.5,3,16.164,3,15.75v-9C3,6.336,3.336,6,3.75,6S4.5,6.336,4.5,6.75v4.189l1.409-1.409
		C6.333,9.106,6.898,8.872,7.5,8.872c0.601,0,1.166,0.234,1.59,0.658l0.582,0.582c0.141,0.141,0.329,0.219,0.53,0.219
		c0.201,0,0.389-0.078,0.531-0.219c0.043-0.043,0.081-0.092,0.113-0.144l0.357-0.598c0.134-0.226,0.381-0.366,0.644-0.366
		c0.135,0,0.268,0.037,0.385,0.106c0.172,0.102,0.293,0.266,0.342,0.46c0.049,0.194,0.02,0.396-0.083,0.568l-0.358,0.6
		c-0.095,0.159-0.209,0.304-0.338,0.433c-0.425,0.425-0.99,0.659-1.592,0.659c0,0,0,0,0,0c-0.601,0-1.166-0.233-1.59-0.657
		l-0.581-0.581c-0.142-0.141-0.33-0.219-0.531-0.219c-0.201,0-0.389,0.078-0.53,0.219L4.5,13.061V15h3.75C8.664,15,9,15.336,9,15.75
		S8.664,16.5,8.25,16.5H3.75z"
        />
        <path
            d="M2.25,21C1.009,21,0,19.991,0,18.75V2.25C0,1.009,1.009,0,2.25,0h10.629c0.601,0,1.165,0.234,1.59,0.658l2.872,2.872
		C17.766,3.955,18,4.52,18,5.121V7.5c0,0.414-0.336,0.75-0.75,0.75S16.5,7.914,16.5,7.5V5.121c0-0.197-0.08-0.39-0.219-0.53
		l-2.872-2.872c-0.142-0.141-0.33-0.219-0.53-0.219H2.25C1.836,1.5,1.5,1.836,1.5,2.25v16.5c0,0.414,0.336,0.75,0.75,0.75H7.5
		c0.414,0,0.75,0.336,0.75,0.75S7.914,21,7.5,21H2.25z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'DataFileAddIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
line {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
