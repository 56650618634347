<template>
    <Icon :name="name" :width="width" :height="height" @click="$emit('click')">
        <path
            class="a"
            d="M13.9,15.5A5.61,5.61,0,0,0,18,17.748a5.25,5.25,0,0,0,0-10.5c-3.75,0-6,5.25-6,5.25s-2.25,5.25-6,5.25a5.25,5.25,0,0,1,0-10.5A5.617,5.617,0,0,1,10.1,9.5"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'LoopIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
