var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListItem',{staticClass:"u-bb historyListItem",class:{
        'location-history-list-item_selectable': _vm.isAccuratePositionMeasurement,
        'location-history-list-item_selected': _vm.selected,
    },on:{"click":function($event){_vm.isAccuratePositionMeasurement ? _vm.$emit('click', _vm.location) : null}}},[_c('div',{staticClass:"l-inline l-spread l-full-width"},[_c('div',{staticClass:"l-inline l-gap-2"},[_c('div',[_c('div',{staticClass:"number-icon",style:({ '--marker-color': _vm.traceBorderColor })},[_c('span',[_vm._v(_vm._s(_vm.location.index))])])]),_c('div',{staticClass:"l-stack l-gap-0"},[_c('h3',[_c('TimeAgo',{attrs:{"from-datetime":_vm.location.timestamp}})],1),_c('p',{staticClass:"t-small"},[_vm._v(" "+_vm._s(_vm.formatTimeWithTime(_vm.location.timestamp))+" ")]),(!_vm.location.is_position_accurate)?_c('p',{staticClass:"l-inline l-center-v l-gap-1 t-small"},[_c('PinNoLocationIcon',{attrs:{"width":"20","height":"16"}}),_c('span',{staticClass:"t-subtle"},[_vm._v(" "+_vm._s(_vm.$t('inaccuratePosition'))+" ")])],1):_vm._e(),(
                        (_vm.location.kind === 'gps-fix' ||
                            _vm.location.kind === 'indoor' ||
                            _vm.location.kind === 'wifi-report') &&
                            _vm.location.gps_accuracy != null
                    )?_c('p',{staticClass:"l-inline l-center-v l-gap-1 t-small"},[_c('PinLocationIcon',{attrs:{"width":"20","height":"16"}}),_c('span',{staticClass:"t-subtle sensor-data-distance"},[_vm._v(" "+_vm._s(_vm.$t('accuracy'))+": "+_vm._s(Math.round(_vm.location.gps_accuracy))+" ")])],1):_vm._e(),(_vm.location.sensor_data.battery != null)?_c('div',{staticClass:"l-inline l-center-v l-gap-1 t-small t-subtle"},[_c('BatteryIcon',{attrs:{"battery-level-in-percent":_vm.location.sensor_data.battery,"width":"20","height":"20","warn":false}}),_c('p',[_vm._v(_vm._s(_vm.location.sensor_data.battery)+" %")])],1):_vm._e()])]),_c('div',{staticClass:"l-inline l-spread l-gap-1"},[(
                    _vm.location.network_data &&
                        _vm.location.network_data.rssi &&
                        _vm.location.network_data.snr
                )?_c('SignalStrengthIndicator',{attrs:{"network-rssi":_vm.location.network_data.rssi,"network-snr":_vm.location.network_data.snr}}):_vm._e(),(_vm.location.kind === 'indoor')?_c('PinHomeIcon',{attrs:{"width":"24","height":"24","name":_vm.$t('HomeIcon')}}):(
                    _vm.location.kind === 'gps-fix' &&
                        _vm.location.is_position_accurate
                )?_c('PinEarthIcon',{attrs:{"width":"24","height":"24","name":_vm.$t('EarthIcon')}}):(
                    _vm.location.kind === 'wifi-report' &&
                        _vm.location.is_position_accurate
                )?_c('PinWifiLocationIcon',{attrs:{"width":"24","height":"24","name":_vm.$t('wifiPosition')}}):(_vm.location.kind === 'gps-fix')?_c('PinNoLocationIcon',{attrs:{"width":"24","height":"24","color":"#fdae61","name":_vm.$t('inaccuratePosition')}}):(_vm.location.kind === 'wifi-report')?_c('PinInaccurateWifiLocationIcon',{attrs:{"width":"24","height":"24","color":"#fdae61","name":_vm.$t('inaccuratePosition')}}):(_vm.location.kind === 'gps-no-fix')?_c('PinWarningIcon',{attrs:{"width":"24","height":"24","name":_vm.$t('WarningIcon'),"color":"#d32f2f"}}):_vm._e(),(_vm.location.is_outside)?_c('LeftZoneIcon',{attrs:{"width":"24","height":"24","name":_vm.$t('LeftZoneIcon')}}):_vm._e(),(
                    _vm.location.event === 'end-trip' ||
                        _vm.location.event === 'start-trip' ||
                        _vm.location.event === 'in-trip'
                )?_c('TripDestinationIcon',{attrs:{"width":"24","height":"24","color-destination-icon":_vm.location.event == 'end-trip' ? '#fdae61' : '#000000',"color-start-icon":_vm.location.event == 'start-trip' ? '#41b883' : '#000000',"color-way-icon":_vm.location.event == 'in-trip' ? '#41b883' : '#000000',"name":_vm.$t(_vm.location.event)}}):(_vm.location.event == 'periodic')?_c('StopwatchIcon',{attrs:{"width":"24","height":"24","name":_vm.$t(_vm.location.event)}}):(_vm.location.event == 'reboot')?_c('RefreshArrowsIcon',{attrs:{"width":"24","height":"24","name":_vm.$t(_vm.location.event)}}):(_vm.location.event == 'manual-trigger')?_c('CogPlayIcon',{attrs:{"width":"24","height":"24","name":_vm.$t(_vm.location.event)}}):(_vm.location.event == 'data-change')?_c('DataFileAddIcon',{attrs:{"width":"24","height":"24","name":_vm.$t(_vm.location.event)}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }