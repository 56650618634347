<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="'none'"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M2.25,18C1.009,18,0,16.991,0,15.75v-3c0-1.241,1.009-2.25,2.25-2.25h1.5C4.991,10.5,6,11.509,6,12.75v3
		C6,16.991,4.991,18,3.75,18H2.25z M2.25,12c-0.414,0-0.75,0.336-0.75,0.75v3c0,0.414,0.336,0.75,0.75,0.75h1.5
		c0.414,0,0.75-0.336,0.75-0.75v-3C4.5,12.336,4.164,12,3.75,12H2.25z"
        />
        <path
            d="M18.75,18C18.336,18,18,17.664,18,17.25s0.336-0.75,0.75-0.75h4.5c0.414,0,0.75,0.336,0.75,0.75S23.664,18,23.25,18H18.75z
		"
        />
        <path
            d="M9.75,18C9.336,18,9,17.664,9,17.25s0.336-0.75,0.75-0.75h4.5c0.414,0,0.75,0.336,0.75,0.75S14.664,18,14.25,18H9.75z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'SearchIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
.b {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
