<template>
    <p :title="title">{{ datetime.fromNow() }}</p>
</template>

<script>
import moment from 'moment-timezone'

export default {
    name: 'TimeAgo',
    props: {
        fromDatetime: {
            type: String,
            required: true,
        },
        nowDatetime: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            interval: null,
        }
    },
    computed: {
        datetime() {
            return moment.min(
                moment(this.fromDatetime),
                this.nowDatetime ? moment(this.nowDatetime) : moment()
            )
        },
        title() {
            return this.datetime.format('DD.MM.YYYY HH:mm')
        },
    },
    created() {
        moment.locale(this.$i18n.locale)

        if (this.nowDatetime === null) {
            this.interval = setInterval(() => this.$forceUpdate(), 30000)
        }
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
}
</script>
