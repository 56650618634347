<template>
    <div>
        <!-- Loading state -->
        <div v-if="isLoading" class="l-stack l-center l-padded">
            <VSpinner size="medium" line-fg-color="#000" :speed="1" />
        </div>

        <!-- Empty state -->
        <div
            v-else-if="!$slots.default"
            class="l-stack l-center l-padded t-small t-subtle"
        >
            {{ $t('noResults') }}
        </div>

        <!-- Render items passed to the slot  -->
        <slot v-else />
    </div>
</template>

<script>
import VSpinner from 'vue-simple-spinner'

export default {
    name: 'List',
    components: {
        VSpinner,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<i18n>
{
    "en": {
        "noResults": "No results",
        "loading": "Loading..."
    },
    "de": {
        "noResults": "Keine Ergebnisse",
        "loading": "Laden..."
    },
    "fr": {
        "noResults": "Aucun résultat",
        "loading": "Chargement..."
    },
    "it": {
        "noResults": "Nessun Risultato",
        "loading":  "Caricare..."
    }
}
</i18n>
