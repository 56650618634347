<template>
    <ListItem
        class="u-bb historyListItem"
        :class="{
            'location-history-list-item_selectable': isAccuratePositionMeasurement,
            'location-history-list-item_selected': selected,
        }"
        @click="isAccuratePositionMeasurement ? $emit('click', location) : null"
    >
        <div class="l-inline l-spread l-full-width">
            <div class="l-inline l-gap-2">
                <div>
                    <div
                        class="number-icon"
                        :style="{ '--marker-color': traceBorderColor }"
                    >
                        <span>{{ location.index }}</span>
                    </div>
                </div>
                <div class="l-stack l-gap-0">
                    <h3>
                        <TimeAgo :from-datetime="location.timestamp" />
                    </h3>
                    <p class="t-small">
                        {{ formatTimeWithTime(location.timestamp) }}
                    </p>

                    <p
                        v-if="!location.is_position_accurate"
                        class="l-inline l-center-v l-gap-1 t-small"
                    >
                        <PinNoLocationIcon width="20" height="16" />

                        <span class="t-subtle">
                            {{ $t('inaccuratePosition') }}
                        </span>
                    </p>

                    <p
                        v-if="
                            (location.kind === 'gps-fix' ||
                                location.kind === 'indoor' ||
                                location.kind === 'wifi-report') &&
                                location.gps_accuracy != null
                        "
                        class="l-inline l-center-v l-gap-1 t-small"
                    >
                        <PinLocationIcon width="20" height="16" />

                        <span class="t-subtle sensor-data-distance">
                            {{ $t('accuracy') }}:
                            {{ Math.round(location.gps_accuracy) }}
                        </span>
                    </p>

                    <div
                        v-if="location.sensor_data.battery != null"
                        class="l-inline l-center-v l-gap-1 t-small t-subtle"
                    >
                        <BatteryIcon
                            :battery-level-in-percent="
                                location.sensor_data.battery
                            "
                            width="20"
                            height="20"
                            :warn="false"
                        />
                        <p>{{ location.sensor_data.battery }} %</p>
                    </div>
                </div>
            </div>
            <div class="l-inline l-spread l-gap-1">
                <SignalStrengthIndicator
                    v-if="
                        location.network_data &&
                            location.network_data.rssi &&
                            location.network_data.snr
                    "
                    :network-rssi="location.network_data.rssi"
                    :network-snr="location.network_data.snr"
                />
                <PinHomeIcon
                    v-if="location.kind === 'indoor'"
                    width="24"
                    height="24"
                    :name="$t('HomeIcon')"
                />
                <PinEarthIcon
                    v-else-if="
                        location.kind === 'gps-fix' &&
                            location.is_position_accurate
                    "
                    width="24"
                    height="24"
                    :name="$t('EarthIcon')"
                />
                <PinWifiLocationIcon
                    v-else-if="
                        location.kind === 'wifi-report' &&
                            location.is_position_accurate
                    "
                    width="24"
                    height="24"
                    :name="$t('wifiPosition')"
                />
                <PinNoLocationIcon
                    v-else-if="location.kind === 'gps-fix'"
                    width="24"
                    height="24"
                    color="#fdae61"
                    :name="$t('inaccuratePosition')"
                />
                <PinInaccurateWifiLocationIcon
                    v-else-if="location.kind === 'wifi-report'"
                    width="24"
                    height="24"
                    color="#fdae61"
                    :name="$t('inaccuratePosition')"
                />
                <PinWarningIcon
                    v-else-if="location.kind === 'gps-no-fix'"
                    width="24"
                    height="24"
                    :name="$t('WarningIcon')"
                    color="#d32f2f"
                />
                <LeftZoneIcon
                    v-if="location.is_outside"
                    width="24"
                    height="24"
                    :name="$t('LeftZoneIcon')"
                />
                <TripDestinationIcon
                    v-if="
                        location.event === 'end-trip' ||
                            location.event === 'start-trip' ||
                            location.event === 'in-trip'
                    "
                    width="24"
                    height="24"
                    :color-destination-icon="
                        location.event == 'end-trip' ? '#fdae61' : '#000000'
                    "
                    :color-start-icon="
                        location.event == 'start-trip' ? '#41b883' : '#000000'
                    "
                    :color-way-icon="
                        location.event == 'in-trip' ? '#41b883' : '#000000'
                    "
                    :name="$t(location.event)"
                />
                <StopwatchIcon
                    v-else-if="location.event == 'periodic'"
                    width="24"
                    height="24"
                    :name="$t(location.event)"
                />
                <RefreshArrowsIcon
                    v-else-if="location.event == 'reboot'"
                    width="24"
                    height="24"
                    :name="$t(location.event)"
                />
                <CogPlayIcon
                    v-else-if="location.event == 'manual-trigger'"
                    width="24"
                    height="24"
                    :name="$t(location.event)"
                />
                <DataFileAddIcon
                    v-else-if="location.event == 'data-change'"
                    width="24"
                    height="24"
                    :name="$t(location.event)"
                />
            </div>
        </div>
    </ListItem>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'

import BatteryIcon from '@/components/icons/BatteryIcon'
import CogPlayIcon from '@/components/icons/CogPlayIcon'
import DataFileAddIcon from '@/components/icons/DataFileAddIcon'
import LeftZoneIcon from '@/components/icons/CursorMoveTargetRightIcon'
import ListItem from '@/components/ListItem'
import PinEarthIcon from '@/components/icons/PinEarthIcon'
import PinHomeIcon from '@/components/icons/PinHomeIcon'
import PinInaccurateWifiLocationIcon from '@/components/icons/PinInaccurateWifiLocationIcon'
import PinLocationIcon from '@/components/icons/PinLocationIcon'
import PinNoLocationIcon from '@/components/icons/PinNoLocationIcon'
import PinWarningIcon from '@/components/icons/PinWarningIcon'
import PinWifiLocationIcon from '@/components/icons/PinWifiLocationIcon'
import RefreshArrowsIcon from '@/components/icons/RefreshArrowsIcon'
import SignalStrengthIndicator from './SignalStrengthIndicator'
import StopwatchIcon from '@/components/icons/ReloadIcon'
import TimeAgo from '@/components/TimeAgo'
import TripDestinationIcon from '@/components/icons/TripDestinationIcon'

export default {
    components: {
        BatteryIcon,
        CogPlayIcon,
        DataFileAddIcon,
        LeftZoneIcon,
        ListItem,
        PinEarthIcon,
        PinHomeIcon,
        PinInaccurateWifiLocationIcon,
        PinLocationIcon,
        PinNoLocationIcon,
        PinWarningIcon,
        PinWifiLocationIcon,
        RefreshArrowsIcon,
        SignalStrengthIndicator,
        StopwatchIcon,
        TimeAgo,
        TripDestinationIcon,
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('map', ['activeLayer']),
        isAccuratePositionMeasurement() {
            return (
                (this.location.kind === 'gps-fix' ||
                    this.location.kind === 'indoor' ||
                    this.location.kind === 'wifi-report') &&
                this.location.is_position_accurate
            )
        },
        traceBorderColor() {
            return this.activeLayer.traceBorderColor || '#228CDB'
        },
    },
    methods: {
        formatTimeWithTime(time) {
            return moment(time).format('DD.MM.YYYY HH:mm:ss')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "HomeIcon": "Indoor position",
        "WarningIcon": "No position",
        "inaccuratePosition": "Inaccurate position",
        "EarthIcon": "GPS position",
        "LeftZoneIcon": "Left zone",
        "accuracy": "Accuracy",
        "start-trip": "start",
        "end-trip": "end",
        "in-trip": "en route",
        "periodic": "periodic",
        "reboot": "reboot",
        "manual-trigger": "manual trigger",
        "data-change": "data changed",
        "wifiPosition": "WiFi position"
    },
    "de": {
        "HomeIcon": "Position im Innenbereich",
        "WarningIcon": "Keine Position",
        "inaccuratePosition": "Ungenaue Position",
        "EarthIcon": "GPS Position",
        "LeftZoneIcon": "Zone verlassen",
        "accuracy": "Genauigkeit",
        "start-trip": "Start",
        "end-trip": "Ende",
        "in-trip": "Unterwegs",
        "periodic": "Periodisch",
        "reboot": "Neustart",
        "manual-trigger": "Manuelle Auslösung",
        "data-change": "Daten Änderung",
        "wifiPosition": "WiFi Position"
    },
    "fr": {
        "HomeIcon": "Position interne",
        "WarningIcon": "Aucune position",
        "inaccuratePosition": "Position imprécise",
        "EarthIcon": "Position GPS",
        "LeftZoneIcon": "Quitter la zone",
        "accuracy": "Précision",
        "start-trip": "Départ",
        "end-trip": "Fin",
        "in-trip": "En route",
        "periodic": "Périodique",
        "reboot": "Redémarrer",
        "manual-trigger": "Trigger manuel",
        "data-change": "Données modifiées",
        "wifiPosition": "Position WiFi"
    },
    "it": {
        "HomeIcon": "Posizione interna",
        "WarningIcon": "Nessuna posizione",
        "EarthIcon": "Posizione GPS",
        "inaccuratePosition": "Posizione imprecisa",
        "LeftZoneIcon": "Escono Zona",
        "accuracy": "Precisione",
        "start-trip": "inizio",
        "end-trip": "fine",
        "in-trip": "sotto forma di",
        "periodic": "periodico",
        "reboot": "riavvio",
        "manual-trigger": "grilletto manuale",
        "data-change": "dati modificati",
        "wifiPosition": "Posizione WiFi"
    }
}
</i18n>

<style lang="scss" scoped>
.location-history-list-item_selected.location-history-list-item_selected {
    background: $color-gray-light;
}

.location-history-list-item_selectable {
    cursor: pointer;

    .number-icon {
        background-color: black;
        background-color: var(--marker-color);
    }
}

.number-icon {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-gray-darker;
    border-radius: 50%;
    color: white;
}

.number-icon span {
    text-align: center;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 0.8em;
}

.location-history-list-item_selected .number-icon {
    background-color: rgb(239, 38, 85);
}
</style>
