<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="''"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M15.845,24.001c-0.764,0-1.496-0.326-2.009-0.894l-0.94-1.042c-0.229-0.253-0.556-0.398-0.898-0.398
		c-0.3,0-0.587,0.11-0.809,0.311c-0.031,0.028-0.06,0.057-0.088,0.088l-0.943,1.043c-0.512,0.567-1.245,0.893-2.012,0.893
		c-0.671,0-1.315-0.247-1.813-0.696c-0.608-0.549-0.933-1.333-0.891-2.151l0.072-1.4c0.034-0.666-0.481-1.236-1.147-1.27
		c-0.021-0.001-0.041-0.002-0.061-0.002c-0.02,0-0.04,0-0.06,0.002l-1.401,0.072c-0.043,0.002-0.083,0.003-0.122,0.003
		c-1.454,0-2.647-1.133-2.715-2.58c-0.038-0.811,0.284-1.589,0.885-2.134l1.044-0.942c0.239-0.216,0.38-0.513,0.396-0.836
		c0.016-0.323-0.093-0.633-0.309-0.872c-0.028-0.031-0.057-0.06-0.088-0.088l-1.041-0.939c-1.109-0.999-1.199-2.716-0.2-3.826
		c0.513-0.569,1.248-0.896,2.016-0.896c0.045,0,0.091,0.001,0.136,0.003l1.401,0.072C4.269,5.525,4.29,5.525,4.312,5.525
		c0.299,0,0.586-0.11,0.808-0.31c0.24-0.216,0.381-0.512,0.398-0.834c0.002-0.043,0.002-0.086,0-0.128L5.446,2.85
		C5.411,2.125,5.66,1.432,6.147,0.896c0.487-0.536,1.153-0.85,1.875-0.884C8.066,0.01,8.11,0.008,8.154,0.008
		c0.761,0,1.491,0.324,2.003,0.888c0,0,0.948,1.043,0.95,1.046c0.216,0.236,0.511,0.376,0.831,0.392
		c0.019,0.001,0.039,0.001,0.059,0.001c0.302,0,0.59-0.111,0.813-0.311c0.031-0.028,0.059-0.056,0.086-0.086l0.941-1.043
		c0.512-0.566,1.245-0.89,2.01-0.89c0.674,0,1.319,0.249,1.818,0.701c0.604,0.547,0.927,1.328,0.886,2.142l-0.072,1.401
		c-0.016,0.322,0.094,0.631,0.311,0.872s0.514,0.381,0.836,0.398c0.02,0.001,0.041,0.002,0.061,0.002s0.041-0.001,0.062-0.002
		l1.4-0.072c0.049-0.003,0.097-0.004,0.146-0.004c1.438,0,2.625,1.127,2.702,2.565c0.044,0.821-0.282,1.607-0.892,2.157l-1.042,0.94
		c-0.495,0.447-0.534,1.213-0.087,1.708c0.026,0.029,0.056,0.059,0.087,0.087l1.042,0.94c1.109,1.001,1.197,2.718,0.197,3.826
		c-0.513,0.568-1.245,0.895-2.008,0.895c0,0,0,0,0,0c-0.048,0-0.095-0.001-0.143-0.004l-1.4-0.072
		c-0.022-0.001-0.043-0.002-0.064-0.002c-0.644,0-1.173,0.505-1.206,1.15c-0.002,0.04-0.002,0.082,0,0.124l0.072,1.397
		c0.077,1.492-1.074,2.768-2.566,2.845C15.94,24,15.893,24.001,15.845,24.001z M4.306,16.983c0.046,0,0.092,0.001,0.137,0.003
		c1.493,0.076,2.645,1.352,2.569,2.844l-0.072,1.4c-0.019,0.365,0.126,0.715,0.397,0.96c0.222,0.201,0.51,0.311,0.809,0.311
		c0.342,0,0.67-0.145,0.898-0.398l0.944-1.044c0.062-0.068,0.127-0.133,0.194-0.194c0.499-0.45,1.143-0.698,1.815-0.698
		c0.766,0,1.5,0.325,2.012,0.892l0.94,1.042c0.232,0.257,0.551,0.399,0.898,0.399c0.021,0,0.042,0,0.063-0.001
		c0.665-0.034,1.178-0.604,1.144-1.27l-0.072-1.4c-0.005-0.092-0.005-0.183,0-0.272c0.073-1.444,1.262-2.576,2.708-2.576
		c0.045,0,0.09,0.001,0.135,0.003l1.401,0.072c0.022,0.001,0.043,0.002,0.065,0.002c0.345,0,0.663-0.142,0.895-0.399
		c0.447-0.495,0.407-1.261-0.088-1.708l-1.042-0.939c-0.069-0.062-0.134-0.127-0.195-0.196c-0.484-0.536-0.731-1.23-0.694-1.954
		s0.353-1.388,0.889-1.873l1.042-0.94c0.273-0.246,0.418-0.597,0.399-0.963c-0.034-0.642-0.562-1.145-1.201-1.145
		c-0.023,0-0.046,0.001-0.069,0.002l-1.403,0.072C19.78,7.019,19.734,7.02,19.688,7.02c-0.046,0-0.093-0.001-0.138-0.004
		c-0.721-0.037-1.386-0.353-1.872-0.891c-0.486-0.538-0.733-1.231-0.696-1.953l0.072-1.4c0.018-0.362-0.126-0.71-0.396-0.955
		c-0.222-0.201-0.511-0.312-0.812-0.312c-0.341,0-0.668,0.145-0.896,0.396l-0.939,1.041c-0.06,0.067-0.126,0.133-0.196,0.196
		c-0.498,0.45-1.142,0.698-1.813,0.698c-0.046,0-0.092-0.001-0.139-0.004C11.14,3.795,10.475,3.479,9.99,2.943L9.047,1.905
		c-0.232-0.256-0.55-0.396-0.895-0.396c-0.02,0-0.039,0-0.059,0.001C7.771,1.525,7.474,1.666,7.257,1.905
		C7.04,2.144,6.929,2.453,6.944,2.776l0.072,1.397c0.005,0.094,0.005,0.19,0,0.287C6.978,5.182,6.661,5.846,6.124,6.33
		c-0.498,0.448-1.141,0.695-1.81,0.695c-0.048,0-0.096-0.001-0.144-0.004L2.77,6.949C2.747,6.948,2.725,6.948,2.703,6.948
		c-0.345,0-0.662,0.142-0.895,0.4C1.593,7.588,1.483,7.897,1.5,8.22c0.017,0.322,0.158,0.619,0.398,0.835l1.042,0.94
		c0.069,0.062,0.134,0.127,0.195,0.195c1.001,1.109,0.913,2.825-0.195,3.826l-1.042,0.94c-0.267,0.242-0.41,0.589-0.393,0.951
		c0.031,0.646,0.561,1.152,1.207,1.152c0.019,0,0.038,0,0.058-0.001l1.397-0.072C4.214,16.984,4.26,16.983,4.306,16.983z"
        />
        <path
            d="M9,16.502c-0.13,0-0.259-0.034-0.372-0.099C8.395,16.269,8.25,16.02,8.25,15.752V8.72c0-0.268,0.145-0.517,0.377-0.651
		C8.74,8.005,8.869,7.97,9,7.97c0.133,0,0.264,0.036,0.379,0.103l6,3.515c0.229,0.134,0.371,0.382,0.371,0.647
		c0,0.265-0.142,0.513-0.371,0.647l-6,3.516C9.265,16.466,9.134,16.502,9,16.502z M9.75,14.443l3.767-2.207L9.75,10.029V14.443z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'CogPlayIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
line {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
