<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <g transform="matrix(1,0,0,1,0,0)">
            <g>
                <line
                    x1="1.75"
                    y1="0.56"
                    x2="22.25"
                    y2="21.06"
                    style="fill: none;stroke-linecap: round;stroke-linejoin: round"
                />
                <path
                    d="M7,2.05A8.8,8.8,0,0,1,12,.56c5.25,0,9,4.26,9,9.5a10.85,10.85,0,0,1-1.36,5"
                    style="fill: none;stroke-linecap: round;stroke-linejoin: round"
                />
                <path
                    d="M18.34,17.15a31.17,31.17,0,0,1-6,6.18.5.5,0,0,1-.61,0C10.12,22.12,3,16.29,3,10.06A9.83,9.83,0,0,1,5.08,3.89"
                    style="fill: none;stroke-linecap: round;stroke-linejoin: round"
                />
                <path
                    d="M9.49,5.24a5,5,0,0,1,6.84,6.82"
                    style="fill: none;stroke-linecap: round;stroke-linejoin: round"
                />
                <path
                    d="M14.92,13.73A5.11,5.11,0,0,1,7.83,6.65"
                    style="fill: none;stroke-linecap: round;stroke-linejoin: round"
                />
            </g>
        </g>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'PinNoLocationIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
