<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M21,10.063c0,6.227-7.121,12.058-8.695,13.27a.5.5,0,0,1-.61,0C10.122,22.122,3,16.29,3,10.063c0-5.246,3.753-9.5,9-9.5S21,4.817,21,10.063Z"
        />
        <circle class="a" cx="12.001" cy="15.063" r="1.5" />
        <path class="a" d="M15.327,11.682a5.5,5.5,0,0,0-6.653,0" />
        <path class="a" d="M18,8.563h0a10,10,0,0,0-12,0H6" />
        <line
            class="a"
            x1="1.276"
            y1="1.339"
            x2="22.723999"
            y2="22.787001"
            style="fill:none;stroke-linecap:round;stroke-linejoin:round"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'PinInaccurateWifiLocationIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
        fillColor: {
            type: String,
            default: '#fff',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
