<template>
    <Icon :name="name" :width="width" :height="height" @click="$emit('click')">
        <path
            class="a"
            d="M2.338,3.255v17.49a1.5,1.5,0,0,0,2.209,1.322L20.87,13.322a1.5,1.5,0,0,0,0-2.644L4.547,1.933A1.5,1.5,0,0,0,2.338,3.255Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ControlsPlayIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
