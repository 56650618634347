<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        fill-color="transparent"
        @click="$emit('click')"
    >
        <rect
            class="a"
            x="0.5"
            y="3.501"
            width="22"
            height="20"
            rx="1"
            ry="1"
        />
        <line class="a" x1="3.5" y1="1.501" x2="3.5" y2="4.501" />
        <line class="a" x1="7.5" y1="1.501" x2="7.5" y2="4.501" />
        <line class="a" x1="11.5" y1="1.501" x2="11.5" y2="4.501" />
        <line class="a" x1="15.5" y1="1.501" x2="15.5" y2="4.501" />
        <line class="a" x1="19.5" y1="1.501" x2="19.5" y2="4.501" />
        <path
            class="a"
            d="M12.5,8.5h3a0,0,0,0,1,0,0v4a0,0,0,0,1,0,0h-4a0,0,0,0,1,0,0v-3A1,1,0,0,1,12.5,8.5Z"
        />
        <path
            class="a"
            d="M15.5,8.5h3a1,1,0,0,1,1,1v3a0,0,0,0,1,0,0h-4a0,0,0,0,1,0,0v-4A0,0,0,0,1,15.5,8.5Z"
        />
        <rect class="a" x="11.5" y="12.501" width="4" height="4" />
        <rect class="a" x="15.5" y="12.501" width="4" height="4" />
        <rect class="a" x="11.5" y="16.501" width="4" height="4" />
        <path
            class="a"
            d="M15.5,16.5h4a0,0,0,0,1,0,0v3a1,1,0,0,1-1,1h-3a0,0,0,0,1,0,0v-4A0,0,0,0,1,15.5,16.5Z"
        />
        <path
            class="a"
            d="M4.5,12.5h3a0,0,0,0,1,0,0v4a0,0,0,0,1,0,0h-4a0,0,0,0,1,0,0v-3A1,1,0,0,1,4.5,12.5Z"
        />
        <rect class="a" x="7.5" y="12.501" width="4" height="4" />
        <path
            class="a"
            d="M3.5,16.5h4a0,0,0,0,1,0,0v4a0,0,0,0,1,0,0h-3a1,1,0,0,1-1-1v-3A0,0,0,0,1,3.5,16.5Z"
        />
        <rect class="a" x="7.5" y="16.501" width="4" height="4" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ReloadIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>
